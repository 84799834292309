import { Table } from './Table'

interface IHasCompositionProps {
  typeForm: 'create' | 'update'
}
export const HasCompositionTab = ({
  typeForm
}: IHasCompositionProps): JSX.Element => {
  const table = Table(typeForm)

  return <div>{table}</div>
}
