import Tooltip from '../Tooltip'
import styled, { css } from 'styled-components'

export const Error = styled(Tooltip)`
  height: 20px;
  width: fit-content;
  position: absolute;
  right: ${props => (props.type ? '35px' : '5px')};
  left: 5px;
  top: 50%;
  width: 20px;
  margin: 0;
  transform: translateY(-50%);
  svg {
    margin: 0;
  }

  span {
    width: max-content;
    background: #c53030;
    color: #fff;
    transform: translateX(-50%);
    &::before {
      border-color: #c53030 transparent;
    }
    ${props =>
      props.position === 'left' &&
      css`
        transform: translateX(-90%);
        &::before {
          left: 90%;
        }
      `}
    ${props =>
      props.position === 'right' &&
      css`
        transform: translateX(-8%);
        &::before {
          left: 8%;
        }
      `}
  }
`
