/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import DataTable from 'components/DataTable'

const StudentView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [student, setStudent] = useState<IStudent | null>(null)
  const searchParametersAuditLog = useRef([
    { entity: 'Student', entity_id: id }
  ])

  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadStudent(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IStudent>(apiList(id))
        const { data } = response
        setStudent(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o estudante',
          description:
            'Houve um error ao carregar o estudante, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadStudent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Estudante removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Estudante não removido, pois ainda está sendo usado.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Estudante removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Estudante não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          // toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Cód.
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{student?.id}</p>
            </div>
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Nome
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{student?.name}</p>
            </div>
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Responsável
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                <a href={`/commercial/clients/view/${student?.client_id}`}>
                  {student?.client?.name}
                </a>
                {/* <Link to={`/commercial/clients/view/${student?.client_id}`}>
                  {student?.client?.name}
                </Link> */}
              </p>
            </div>
          </div>

          <div className="row mb-5">
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Instituição
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {student?.institution?.company_social_name}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Data de nascimento
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {typeof student?.birth_date === 'string' &&
                  student?.birth_date.slice(0, 10)}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Sexo
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{student?.gender}</p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Ano
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {student?.school_year}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Turno
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {student?.school_period}
              </p>
            </div>
            <div className="form-group col-md-4">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Sala
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {student?.school_room}
              </p>
            </div>
          </div>
          <div className="form-group col-md-12 mb-5">
            <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
              Observação
            </label>
            <p className="fw-bolder fs-6 text-gray-800">
              {student?.observation}
            </p>
          </div>
          <div className="row mb-5">
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="active"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Ativo
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {student?.is_active === true ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="created"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cadastrado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {student?.created_at}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab title="Histórico" key={0}>
                  <div className="portlet light">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
                <></>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${student?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(student?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default StudentView
