import { useEffect, useState } from 'react'
import { Container, IconRemove } from './style'
import { v4 as uuidv4 } from 'uuid'

import { SearchComponentProduct } from '../SearchComponentProduct'
import { Input, Select as CustomSelect } from 'components/Form'
import api from 'services/api'
import { apiListDishTypes } from '../../../domain/api'
import { useToast } from 'hooks/toast'
import { useMenuFoodContext } from '../../../contexts'

export const Table = (typeForm: 'create' | 'update'): JSX.Element => {
  const { composition, setComposition, checkError, onChangeOfActualErrors } =
    useMenuFoodContext()
  const { addToast } = useToast()

  const [dishTypeList, setDishTypeList] = useState<IDishType[]>([])
  useEffect(() => {
    if (dishTypeList.length === 0) {
      api
        .get(apiListDishTypes())
        .then(res => {
          const orderedData: IDishType[] = res.data.sort(
            (a: IDishType, b: IDishType) => a.name.localeCompare(b.name)
          )
          setDishTypeList(orderedData)
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao carregar as instituições',
            description:
              'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
          })
        })
    }
  }, [addToast, dishTypeList])

  const activeDishTypeList = dishTypeList.filter(dishType => dishType.is_active)

  return (
    <Container className="table-responsive">
      <div className="table-content-relative">
        <table className="table table-bordered mb-0">
          <tbody>
            <tr>
              <th className="fw-bold fs-6">Tipo</th>
              <th className="fw-bold fs-6">Item</th>
              <th className="fw-bold fs-6">Quantidade</th>
              <th className="fw-bold fs-6">Ações</th>
            </tr>
            {composition?.map((item, index: number) => {
              return (
                <tr key={index}>
                  <td className="col-md-3">
                    <CustomSelect
                      name={`composition.${index}.dish_type`}
                      options={
                        typeForm === 'create'
                          ? activeDishTypeList.map(dishType => ({
                              name: dishType.name,
                              value: dishType.id
                            }))
                          : dishTypeList.map(dishType => ({
                              name: dishType.name,
                              value: dishType.id
                            }))
                      }
                      rules={{ required: true }}
                      onChange={event => {
                        setComposition(prev => {
                          const copy = [...prev]
                          copy[index].dish_type_id = Number(event.target.value)
                          copy[index].dish_name = undefined
                          copy[index].dish_id = undefined
                          return copy
                        })
                        onChangeOfActualErrors(item.id, 'error_type')
                      }}
                      value={composition[index].dish_type_id}
                      blank
                      nestedError={checkError(item.id, 'error_type')}
                      controlled
                      defaultValue={''}
                    />
                  </td>

                  <td>
                    {SearchComponentProduct({
                      index,
                      composition,
                      setComposition,
                      dishTypeList,
                      typeForm,
                      checkError,
                      onChangeOfActualErrors
                    })}
                  </td>
                  <td style={{ width: '70px' }}>
                    <Input
                      name={`composition.${index}.dish_amount`}
                      type="number"
                      min={0}
                      onChange={event => {
                        setComposition(prev => {
                          const copy = [...prev]
                          copy[index].dish_amount = Number(event.target.value)
                          return copy
                        })
                        onChangeOfActualErrors(item.id, 'error_amount')
                      }}
                      rules={{ position: 'left' }}
                      controlled
                      nestedError={checkError(item.id, 'error_amount')}
                      value={composition[index].dish_amount}
                    />
                  </td>
                  <td className="actions" style={{ width: '70px' }}>
                    <div className="d-flex  justify-content-center align-items-center pt-4 pe-4">
                      <IconRemove
                        onClick={() =>
                          setComposition(prev => {
                            const copy = [...prev]
                            copy.splice(index, 1)
                            return copy
                          })
                        }
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        className="btn btn-dark btn-sm mb-5"
        onClick={() =>
          setComposition(prev => {
            const copy = [...prev]
            const final = [
              ...copy,
              {
                id: uuidv4(),
                dish_id: undefined,
                dish_type_id: undefined,
                dish_amount: undefined,
                dish_name: undefined
              }
            ]
            return final
          })
        }
      >
        + Item
      </button>
      {/* <div style={{ height: '152px' }}></div> */}
    </Container>
  )
}
