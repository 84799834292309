import DataTable from '../../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../../components/Container'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'

const ClientList = (): JSX.Element => (
  <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={toolsList}>
    <DataTable
      source={nameSource}
      entity={nameEntity}
      format={{ orderBy: 'name' }}
      notHasChildren
      headers={headers}
      actions={[
        {
          name: 'Visualizar',
          title: 'Visualizar',
          spanIcon: 'fa fa-search',
          linkTo: order => `/commercial/clients/view/${order.id}`,
          style: { margin: '0 5px' }
        },
        {
          name: 'Editar',
          title: 'Editar',
          spanIcon: 'fa fa-edit',
          linkTo: order => `/commercial/clients/update/${order.id}`
        }
      ]}
    />
  </Container>
)

export default ClientList
