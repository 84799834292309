import { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'

import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import DataTable from 'components/DataTable'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import { headerMenuDishes, headerMenuInstitutions } from '../domain/headers'

const MenuFoodView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [menuFood, setMenuFood] = useState<IMenuFoodData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const searchParams = useRef([{ menu_food_id: `${id}` }])
  const searchParametersAuditLog = useRef([
    { entity: 'MenuFood', entity_id: id }
  ])

  const { disableLoading, activeLoading } = useLoading()
  useEffect(() => {
    async function loadInstitution(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IMenuFoodData>(apiList(id))
        const { data } = response
        setMenuFood(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o item',
          description:
            'Houve um error ao carregar o item, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadInstitution()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Cardápio removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title:
          'Não foi possível remover o cardápio, pois ele está registrado em um plano.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover esse item, pois ele está registrado num menu.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Cód.
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{menuFood?.id}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Tipo
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {menuFood?.menu_food_type?.name}
              </p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Nome
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{menuFood?.name}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Período
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {`${menuFood?.start_validity.slice(
                  0,
                  10
                )} -  ${menuFood?.end_validity.slice(0, 10)}`}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <label htmlFor="name" className="col-form-label">
                Descrição
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {menuFood?.description}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Ativo
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {menuFood?.is_active === true ? 'Sim' : 'Não'}
              </p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Cadastrado em:
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {menuFood?.created_at}
              </p>
            </div>
            <div className="separator my-5" />
            <div className="row">
              <div className="col-md-12">
                <Tabs>
                  <Tab key={0} title="Itens">
                    <div className="portlet light">
                      <div className="card-header p-0">
                        <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                          Listagem
                        </div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          format={{ orderBy: 'id' }}
                          source="menu_food_dishes"
                          entity="MenuFoodDish"
                          onlyView
                          headers={headerMenuDishes}
                          searchParameters={searchParams.current}
                          actions={[
                            {
                              name: 'view',
                              title: 'Visualizar',
                              spanIcon: 'fa fa-search',
                              linkTo: dish =>
                                `/commercial/dishes/view/${dish.id}`,
                              style: { margin: '0 5px' }
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab key={1} title="Instituições">
                    <div className="portlet light">
                      <div className="card-header p-0">
                        <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                          Listagem
                        </div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          format={{ orderBy: 'id' }}
                          source="commercial/menuFoodInstitutions"
                          entity="MenuFoodInstitution"
                          onlyView
                          headers={headerMenuInstitutions}
                          searchParameters={searchParams.current}
                          actions={[
                            {
                              name: 'view',
                              title: 'Visualizar',
                              spanIcon: 'fa fa-search',
                              linkTo: institution =>
                                `/commercial/institutions/view/${institution.id}`,
                              style: { margin: '0 5px' }
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab title="Histórico" key={2}>
                    <div className="portlet light">
                      <div
                        className="portlet-title mt-3 d-flex justify-content-between"
                        style={{ height: '34.4px' }}
                      >
                        <div className="caption fs-5 fw-bold">Listagem</div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          entityId={id}
                          format={{ orderBy: '' }}
                          source="auditLogs"
                          entity="AuditLog"
                          searchParameters={searchParametersAuditLog.current}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${menuFood?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(menuFood?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default MenuFoodView
