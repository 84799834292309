/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameActions, nameEntity } from '../domain/info'
import {
  apiDelete,
  apiGetPdfContract,
  apiList,
  apiStudentDelete
} from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { currencyMask, phoneMask } from '../../../../../utlis/mask'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import { LinkContainer, MenuTableRow } from './styles'
import DataTable from 'components/DataTable'
import { FormStudent } from '../../Students/components/Form'
import Modal from 'components/Modal'

const ClientView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [client, setClient] = useState<IClientData | null>(null)
  const [currentStudentUpdate, setCurrentStudentUpdate] = useState<IStudent>()
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])

  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const { disableLoading, activeLoading } = useLoading()

  const refModal = useRef(null)

  const [modalTypeOpen, setModalTypeOpen] = useState<TModalType>('closed')
  const [idParentCreate, setIdParentCreate] = useState<number>()

  const handleClickOnClose = () => {
    setModalTypeOpen('closed')
  }

  const handlerOnClickButtonEditInCurrentRow = (currentValue: IStudent) => {
    setCurrentStudentUpdate(currentValue)
    setModalTypeOpen('update')
  }

  const handleClickOnOpenModalCreate = (id: number) => {
    setIdParentCreate(id)
    setModalTypeOpen('create')
  }

  const handlerOnClickButtonRemoveInCurrentRow = ({ id, name }: IStudent) => {
    setIsActiveAlert({
      id,
      name,
      isActive: true
    })
  }

  async function loadClient(): Promise<void> {
    activeLoading()
    try {
      const response = await api.get<IClientData>(apiList(id))
      const { data } = response
      setClient(data)
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o cliente',
        description:
          'Houve um error ao carregar o cliente, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }
  useEffect(() => {
    loadClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const getPdfContract = (id: number) => {
    activeLoading()

    api
      .get(apiGetPdfContract(id.toString()), { responseType: 'blob' })
      .then(res => {
        window.open(URL.createObjectURL(res.data))
        disableLoading()
      })
  }

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiStudentDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Estudante removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Estudante não removido, pois ainda está sendo usado.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      loadClient()
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Cliente removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Cliente não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="form-group col-md-3">
              <label
                htmlFor="id"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Cód.
              </label>
              <p id="id" className="fw-bolder fs-6 text-gray-800">
                {client?.id}
              </p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="name"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Nome
              </label>
              <p id="name" className="fw-bolder fs-6 text-gray-800">
                {client?.name}
              </p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="rg"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                RG
              </label>
              <p className="fw-bolder fs-6 text-gray-800" id="rg">
                {client?.identity_card}
              </p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="cpf"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                CPF
              </label>
              <p className="fw-bolder fs-6 text-gray-800" id="cpf">
                {client?.cpf}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="form-group col-md-3">
              <label
                htmlFor="id"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Data de nascimento
              </label>
              <p id="id" className="fw-bolder fs-6 text-gray-800">
                {typeof client?.birth_date === 'string' &&
                  client?.birth_date.slice(0, 10)}
              </p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="name"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Sexo
              </label>
              <p id="name" className="fw-bolder fs-6 text-gray-800">
                {client?.gender}
              </p>
            </div>
          </div>

          <div className="separator my-5" />
          <div className="row mb-5">
            <div className="form-group col-md-3">
              <label
                htmlFor="zip_code"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                CEP
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{client?.zip_code}</p>
            </div>

            <div className="form-group col-md-3">
              <label
                htmlFor="street"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Logradouro
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{client?.street}</p>
            </div>

            <div className="form-group col-md-3">
              <label
                htmlFor="zip_code"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Número
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{client?.number}</p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="zip_code"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Complemento
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {client?.complement}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="form-group col-md-3">
              <label
                htmlFor="district"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Bairro
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{client?.district}</p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="city"
                className="col-form-label fw-bold fs-6form-labelfw-bold fs-6"
              >
                Cidade
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {client?.city} | {client?.state}
              </p>
            </div>
          </div>
          <div className="separator my-5" />

          <div className="row mb-5">
            <div className="form-group col-md-3">
              <label
                htmlFor="phone"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Telefone 1
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {phoneMask(client?.phone || '')}
              </p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="phone_2"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Telefone 2
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {phoneMask(client?.phone_2 || '')}
              </p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="email"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                E-mail
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{client?.email}</p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="email"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                E-mail 2
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{client?.email_2}</p>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row">
            <div className="form-group col-md-12">
              <label
                htmlFor="note"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Observações
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {client?.observation}
              </p>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row mb-5">
            <div className="form-group col-md-3">
              <label
                htmlFor="active"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Ativo
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {client?.is_active === true ? 'Sim' : 'Não'}
              </p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="created"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Cadastrado em
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {client?.created_at}
              </p>
            </div>
            <div className="form-group col-md-3">
              <label
                htmlFor="updated"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Atualizado em
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {client?.updated_at}
              </p>
            </div>
          </div>

          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab key={0} title="Estudantes">
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                      <div className="">
                        <a
                          href="#"
                          onClick={() =>
                            handleClickOnOpenModalCreate(Number(id))
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="fa fa-plus" /> Adicionar
                        </a>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Instituição</th>
                            <th>Estudante</th>
                            <th>Data de nascimento</th>
                            <th>Turno</th>
                            <th>Ano</th>
                            <th>Ativo</th>
                            <th>Cadastrado em</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {client?.students?.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {client?.students?.map(student => {
                            return (
                              <MenuTableRow key={student.id}>
                                <td>
                                  {student.institution.company_social_name}
                                </td>
                                <td>{student.name}</td>
                                <td>{student.birth_date}</td>
                                <td>{student.school_period}</td>
                                <td>{student.school_year}</td>
                                <td>
                                  {student.is_active === true ? 'Sim' : 'Não'}
                                </td>
                                <td>{student.created_at}</td>
                                <td>
                                  <LinkContainer className="gap-2">
                                    <a
                                      href={`/commercial/students/view/${student.id}`}
                                      style={{
                                        border: 'none',
                                        color: '#009EF7',
                                        marginRight: '-0.4rem'
                                      }}
                                    >
                                      <span className="fa fa-search" />
                                    </a>
                                    <a
                                      href={`/commercial/contracts/students/${student.id}/create`}
                                      style={{
                                        border: 'none',
                                        color: '#009EF7'
                                      }}
                                    >
                                      <span className="fa fa-file-contract" />
                                    </a>
                                    <button
                                      onClick={() =>
                                        handlerOnClickButtonEditInCurrentRow(
                                          student
                                        )
                                      }
                                      style={{
                                        border: 'none',
                                        color: '#009EF7',
                                        background: 'none'
                                      }}
                                    >
                                      <span className="fa fa-edit" />
                                    </button>
                                    <button
                                      onClick={() =>
                                        handlerOnClickButtonRemoveInCurrentRow(
                                          student
                                        )
                                      }
                                      className="text-decoration-none"
                                      style={{
                                        border: 'none',
                                        color: '#009EF7',
                                        background: 'none'
                                      }}
                                    >
                                      <span className="fa fa-remove" />
                                    </button>
                                  </LinkContainer>
                                </td>
                              </MenuTableRow>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>

                <Tab title="Contratações" key={1}>
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Instituição</th>
                            <th>Estudante</th>
                            <th>Periodicidade</th>
                            <th>Valor total</th>
                            <th>Situação</th>
                            <th>Cadastrado em</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {client?.contracts?.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {client?.contracts?.map(contract => {
                            return (
                              <MenuTableRow key={contract.id}>
                                <td>
                                  {
                                    client?.students?.find(
                                      ({ institution_id }) =>
                                        institution_id ===
                                        contract.institution_id
                                    )?.institution.company_name
                                  }
                                </td>
                                <td>
                                  {
                                    client?.students?.find(
                                      ({ id }) => id === contract.student_id
                                    )?.name
                                  }
                                </td>
                                <td>{contract.periodicity_payament}</td>
                                <td>{currencyMask(contract.value_payament)}</td>
                                <td>{contract.situation}</td>
                                <td>{contract.created_at}</td>
                                <td>
                                  <LinkContainer className="gap-2">
                                    <a
                                      href={`/commercial/contracts/view/${contract.id}`}
                                      style={{
                                        border: 'none',
                                        color: '#009EF7',
                                        marginRight: '-0.4rem'
                                      }}
                                    >
                                      <span className="fa fa-search" />
                                    </a>
                                    <div>
                                      <span
                                        className="fa fa-download"
                                        style={{
                                          border: 'none',
                                          color: '#009EF7'
                                        }}
                                        onClick={() =>
                                          getPdfContract(contract.id)
                                        }
                                      />
                                    </div>
                                  </LinkContainer>
                                </td>
                              </MenuTableRow>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>{' '}
                </Tab>
                <Tab title="Histórico" key={2}>
                  <div className="portlet light">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalTypeOpen !== 'closed'}
        pageTitle={modalTypeOpen === 'create' ? 'Adicionar' : 'Editar'}
        style={{
          stylesModal: {
            height: '100%'
          }
        }}
        Children={
          <FormStudent
            typeForm={modalTypeOpen === 'create' ? 'create' : 'update'}
            initialValues={
              modalTypeOpen === 'update' && {
                idUpdate: currentStudentUpdate.id,
                ...currentStudentUpdate
              }
            }
            clientInfo={{
              id: client?.id,
              name: client?.name
            }}
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(idParentCreate)
            }}
            updateOnSubmit={loadClient}
          />
        }
      />
      <Alert
        message={`Tem certeza que deseja excluir o aluno ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o cliente ${client?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(client?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default ClientView
