export const headers = [
  {
    name: 'Instituição',
    field: 'institution.company_social_name',
    sortable: true
  },
  { name: 'Responsável', field: 'client.name', sortable: false },
  { name: 'Aluno', field: 'name', sortable: false },
  { name: 'Telefone', field: 'client.phone', sortable: false },
  { name: 'Turno', field: 'school_period', sortable: false },
  { name: 'Ano', field: 'school_year', sortable: false },
  {
    name: 'Ativo',
    field: 'is_active',
    sortable: false,
    hasTypes: {
      types: {
        true: 'Sim',
        false: 'Não'
      }
    }
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
