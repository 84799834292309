import DishesList from '../../pages/Commercial/MenuFoods/Dishes/List'
import DishesCreate from '../../pages/Commercial/MenuFoods/Dishes/Create'
import DishesView from '../../pages/Commercial/MenuFoods/Dishes/View'
import DishesUpdate from '../../pages/Commercial/MenuFoods/Dishes/Update'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const DishesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/dishes" exact component={DishesList} />
    <Route path="/commercial/dishes/create" component={DishesCreate} exact />
    <Route path="/commercial/dishes/view/:id" component={DishesView} exact />
    <Route
      path="/commercial/dishes/update/:id"
      component={DishesUpdate}
      exact
    />
  </CustomSwitch>
)
