/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'

import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import DataTable from 'components/DataTable'

const DishView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [dish, setDish] = useState<IDishData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const searchParametersAuditLog = useRef([{ entity: 'Dish', entity_id: id }])

  const { disableLoading, activeLoading } = useLoading()
  useEffect(() => {
    async function loadInstitution(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IDishData>(apiList(id))
        const { data } = response
        setDish(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o item',
          description:
            'Houve um error ao carregar o item, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadInstitution()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title:
          'Não foi possível remover o item, pois ele está registrado em um menu.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover esse item, pois ele está registrado num menu.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Cód
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{dish?.id}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Tipo
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {dish?.dish_type.name}
              </p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Nome
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{dish?.name}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Ativo
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {dish?.is_active ? 'Sim' : 'Não'}
              </p>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-12">
              <label htmlFor="name" className="col-form-label">
                Descrição
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {dish?.description}
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <label htmlFor="name" className="col-form-label">
                Cadastrado em:
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{dish?.created_at}</p>
            </div>
          </div>
        </div>
        <div className="separator my-5" />
        <div className="row">
          <div className="col-md-12">
            <Tabs>
              <Tab title="Histórico" key={0}>
                <div className="portlet light">
                  <div
                    className="portlet-title mt-3 d-flex justify-content-between"
                    style={{ height: '34.4px' }}
                  >
                    <div className="caption fs-5 fw-bold">Listagem</div>
                    <div className="tools"></div>
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      entityId={id}
                      format={{ orderBy: '' }}
                      source="auditLogs"
                      entity="AuditLog"
                      searchParameters={searchParametersAuditLog.current}
                    />
                  </div>
                </div>
              </Tab>
              <></>
            </Tabs>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${dish?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(dish?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default DishView
