import { PageLink } from 'assets/layout/core'
import { TypeBreadcrumb } from '../index'

// export const breadcrumbUpdate: TypeBreadcrumb[] = [
//   {
//     name: 'Início',
//     to: '/'
//   },
//   {
//     name: 'Configuração'
//   },
//   {
//     name: 'Perfil'
//   }
// ]

export const breadcrumbUpdate: PageLink[] = [
  {
    title: 'Perfil',
    path: '/users/profile',
    isSeparator: false,
    isActive: false
  },
  {
    title: 'Editar',
    path: '',
    isSeparator: true,
    isActive: false
  }
]
