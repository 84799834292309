import { convertValueMaskInNumberWithTwoZeroDigits, currencyMask } from './mask'

export const calculateDiscountToBRL = (
  discount: string,
  totalValue: number
): string => {
  if (!discount || !totalValue) {
    return currencyMask('0')
  }
  return currencyMask(
    ((+discount.replace(',', '.') * totalValue) / 100).toString()
  )
}

export const calculateValueWithDiscountToBRL = (
  discount: string,
  value: number
): string => {
  if (!value) {
    return currencyMask('0')
  }
  if (!discount) {
    return currencyMask(value)
  }
  if (discount) {
    const discountToNumber = Number(discount.replace(',', '.'))
    if (isNaN(discountToNumber) || discountToNumber === 0) {
      return currencyMask(value)
    }
    const valueWithDiscount = (+value * (100 - +discountToNumber)) / 100
    return currencyMask(valueWithDiscount)
  }
  return currencyMask(value)
}

export const calculateValueWithDiscountToNumber = (
  discount: string,
  value: number
): number => {
  const finalValue =
    ((100 - convertValueMaskInNumberWithTwoZeroDigits(discount)) * value) / 100
  // convertValueMaskInNumberWithTwoZeroDigits
  return Number(finalValue.toFixed(2))
}
