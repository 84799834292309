export const apiCreate = (): string => '/commercial/plans/create/'
export const apiList = (id?: string): string =>
  !id ? '/commercial/plans/' : `/commercial/plans/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/plans/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/plans/delete/${id}`

export const apiListMenuFood = (id?: string): string =>
  !id ? '/commercial/menufoods/' : `/commercial/menufoods/view/${id}`

export const apiCreatePlanFrequency = (): string =>
  '/commercial/plansfrequency/create/'
export const apiUpdatePlanFrequency = (id: string): string =>
  `/commercial/plansfrequency/update/${id}`
