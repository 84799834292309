import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import {
  apiCreatePlanFrequency,
  apiUpdatePlanFrequency
} from '../../domain/api'
import { nameActions } from '../../domain/info'
import {
  PAYAMENT_FORM_PERIODICITIES,
  PLAN_FREQUENCIES
} from 'commons/constants/commercial'
import { IPlanFrequencyDefaultValues } from '../../View'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IPlanFrequencyDefaultValues & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
  plan: { id: number; name: string }
}

export const FormPlanFrequency = ({
  isOpenInModal,
  initialValues,
  typeForm,
  plan
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const [defaultValues, setDefaultValues] = useState<any>()
  const { updateDataTable } = useUpdateDataTable()
  const [hasUpdated, setHasUpdated] = useState(false)
  useEffect(() => {
    if (initialValues && !hasUpdated) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues, hasUpdated])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (
    dataForm: Omit<IPlanFrequencyDefaultValues, 'planName'>
  ) => {
    const id = initialValues?.idUpdate
    const payament_form = JSON.stringify({
      periodicity: dataForm.periodicity,
      value: dataForm.value
    })
    const data: Omit<IPlanFrequency, 'created_at' | 'updated_at' | 'id'> = {
      plan_id: plan.id,
      payament_form,
      frequency: dataForm.frequency,
      is_active: dataForm.is_active
    }
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data
          }
          activeLoading()

          try {
            await api.post(apiCreatePlanFrequency(), dataCreate)
            handleOnClose()
            updateDataTable()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error: any) {
            console.log(error.response.data)

            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            updateDataTable()
            disableLoading()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }
            console.log(dataCreate)

            activeLoading()
            await api.post(apiCreatePlanFrequency(), dataCreate)
            updateDataTable()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            updateDataTable()
            disableLoading()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdatePlanFrequency(String(id)), dataUpdate)
            updateDataTable()
            // setHasUpdated(true)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }
          try {
            activeLoading()
            const res = await api.put(
              apiUpdatePlanFrequency(String(id)),
              dataUpdate
            )
            setDefaultValues(res.data)
            updateDataTable()
            setHasUpdated(true)

            disableLoading()

            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            // history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <div className="card">
        <div className="pe-4 ps-4 row">
          <Select
            className={'col-md-6'}
            name="frequency"
            label="Frequência"
            options={PLAN_FREQUENCIES.map(frequency => ({
              name: frequency.name,
              value: frequency.value
            }))}
            rules={{ required: true }}
            blank
          />
          <Select
            className={'col-md-6'}
            name="periodicity"
            label="Periodicidade de contratação"
            options={PAYAMENT_FORM_PERIODICITIES.map(periodicity => ({
              name: periodicity,
              value: periodicity
            }))}
            rules={{ required: true }}
            blank
          />

          <Input
            name="value"
            className={'col-md-6'}
            label="Valor"
            price
            rules={{ required: true, position: 'left' }}
          />
          {typeForm === 'update' && (
            <Select
              className="col-md-6"
              name="is_active"
              label="Ativo"
              options={[
                {
                  name: 'Sim',
                  value: 'true'
                },
                {
                  name: 'Não',
                  value: 'false'
                }
              ]}
              blank
              defaultValue={'true'}
            />
          )}
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}
