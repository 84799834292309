import { Error } from './styles'
import { FiAlertCircle } from 'react-icons/fi'

interface IToolTipError {
  title: string
  position?: string
}

const ToolTipError = ({ title, position }: IToolTipError) => {
  return (
    <Error title={title} position={position}>
      <FiAlertCircle color="#c53030" size={20} />
    </Error>
  )
}

export default ToolTipError
