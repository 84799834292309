import { StepperStlyed } from './styles'

interface StepperProps {
  steps: {
    step: string
    title: string
    description?: string
    status: string
    step_name: TActualStep
  }[]
  changeStep: any
}

export const Stepper: React.FC<StepperProps> = ({ steps, changeStep }) => {
  return (
    <StepperStlyed>
      <div className="stepper stepper-pills">
        <div className="stepper-nav justify-content-between flex-wrap mb-10">
          {steps.map(step => (
            <div
              key={step.step}
              className={`stepper-item  mx-2 my-4 ${step.status}`}
              data-kt-stepper-element="nav"
            >
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">{step.step}</span>
              </div>

              <div className="stepper-label">
                <h3
                  className="stepper-title"
                  style={{ cursor: 'pointer' }}
                  onClick={() => changeStep(step.step_name)}
                >
                  {step.title}
                </h3>

                {!!step.description && (
                  <div className="stepper-desc">{step.description}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </StepperStlyed>
  )
}
