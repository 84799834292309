import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'
import ProfileUpdate from 'pages/Users/Profile'
import List from '../../pages/Users/UsersPage/List'
import Update from '../../pages/Users/UsersPage/Update'
import Create from '../../pages/Users/UsersPage/Create'
import View from '../../pages/Users/UsersPage/View'

export const UsersRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/users" exact component={List} />
    <Route path="/users/profile" exact component={ProfileUpdate} />
    <Route path="/users/update/:id" component={Update} exact />
    <Route path="/users/create" component={Create} exact />
    <Route path="/users/view/:id" component={View} exact />
  </CustomSwitch>
)
