import styled from 'styled-components'

export const WrapperTablePortlet = styled.div`
  div.switchButton {
    border-radius: unset !important;
  }
`

export const MenuTable = styled.table`
  /* width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th:last-child {
    text-align: center;
  }

  td,
  th,
  thead {
    padding: 8px 10px;
    border: 1px solid #e7ecf1;
  } */
`
export const MenuTableRow = styled.tr`
  background: #fbfcfd;
  td {
    border: 1px solid #eff2f5;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin: 0px 0.4em;

    cursor: pointer;
  }

  a + a {
    margin-left: 5px;
  }
`
