import InstitutionsList from '../../pages/Commercial/Institutions/List'
import InstitutionsUpdate from '../../pages/Commercial/Institutions/Update'
import InstitutionsCreate from '../../pages/Commercial/Institutions/Create'
import InstitutionsView from '../../pages/Commercial/Institutions/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const InstitutionsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/institutions" exact component={InstitutionsList} />
    <Route
      path="/commercial/institutions/create"
      component={InstitutionsCreate}
      exact
    />
    <Route
      path="/commercial/institutions/view/:id"
      component={InstitutionsView}
      exact
    />
    <Route
      path="/commercial/institutions/update/:id"
      component={InstitutionsUpdate}
      exact
    />
  </CustomSwitch>
)
