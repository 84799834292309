export const apiCreate = (): string => '/commercial/students/create/'
export const apiList = (id?: string): string =>
  !id ? '/commercial/students/' : `/commercial/students/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/students/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/students/delete/${id}`

export const apiCreateStudentRestriction = (): string =>
  '/commercial/studentsfoodrestrictions/create/'

export const apiUpdateStudentRestriction = (id: string): string =>
  `/commercial/studentsfoodrestrictions/update/${id}`

export const apiListInstitution = (): string => '/commercial/institutions/'
