import React from 'react'
import avatar from '../../assets/media/avatars/300-1.jpg'
import { HeaderUserMenu } from '../HeaderUserMenu'

import { KTSVG } from '../../assets/helpers'

export interface IPropsHeader {
  avatarURL?: string
}

const Header = ({ avatarURL }: IPropsHeader) => {
  return (
    <div id="kt_header" className="header">
      <div
        className={
          'd-flex align-items-stretch justify-content-end container-fluid'
        }
      >
        <div className="d-flex align-items-stretch flex-shrink-0">
          <div
            className={'d-flex align-items-stretch flex-shrink-0'}
            id="kt_header_user_menu_toggle"
          >
            <div
              className={
                'cursor-pointer symbol d-flex align-items-center ms-1 ms-lg-3'
              }
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
            >
              <img
                src={avatarURL || avatar}
                alt="user"
                style={{ objectFit: 'cover' }}
              />
            </div>
            <HeaderUserMenu avatarURL={avatarURL || avatar} />

            <div
              className="d-flex align-items-center d-lg-none ms-2 me-n3"
              title="Show header menu"
            >
              <div
                className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                id="kt_header_menu_mobile_toggle"
              >
                <KTSVG
                  path="/media/icons/duotune/text/txt001.svg"
                  className="svg-icon-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
