import Container from 'components/Container'
import React, { useState } from 'react'
import { FormLabel } from './components/Form'
import { breadcrumbUpdate } from './domain/breadcrumb'
import { toolsUpdate } from './domain/tools/update'

const Labels = (): JSX.Element => {
  return (
    <Container
      pageTitle="Editar"
      tools={toolsUpdate}
      breadcrumb={breadcrumbUpdate}
    >
      <FormLabel />
    </Container>
  )
}

export default Labels
