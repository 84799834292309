export const apiCreate = (): string => '/commercial/contracts/create/'

export const apiGetStudent = (id: string): string =>
  `/commercial/students/view/${id}`

export const apiListMenuFoodTypes = (): string => '/commercial/menuFoodTypes'

export const apiListInstitution = (): string => '/commercial/institutions/'

export const apiGetContract = (id: string): string =>
  `/commercial/contracts/view/${id}`

export const apiListMenuFoodDishes = (id: string): string =>
  `/commercial/menuFoods/view/${id}`

export const apiListPlans = (): string => '/commercial/plans'

export const apiUpdate = (id: string): string =>
  `/commercial/institutions/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/institutions/delete/${id}`

export const apiGetPdfContract = (id: string): string =>
  `/commercial/contracts/generatePdf/${id}`

export const apiCreateFinancialMoviment = () => '/financial/financialMoviments'

export const apiListFinancialMoviment = () => '/financial/financialMoviments'

export const apiGetPdfFinancialMoviment = (id: string) =>
  `/financial/financialMovimentsPayments/${id}`
