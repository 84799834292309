import { ToolsContainerProps } from '../../../../../../components/Container'
import { nameActions } from '../../info'

export const toolsCreate: ToolsContainerProps[] = [
  // {
  //   name: 'Voltar',
  //   to: nameActions.back.to,
  //   icon: nameActions.back.icon,
  //   hasParams: false
  // },
]
