import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Select } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { FormContent, IconRemove } from './styles'
import { SearchComponentProduct } from '../SearchComponentProduct'
import { filterDuplicatedsObject } from 'utlis/filterDuplicatesObject'

type PermissionAssociatedData = {
  id: number
  permission_id: number
  routes?: number[]
  routesAssociated?: PermissionData[]
  permission?: PermissionData
  grouped?: string
  function?: string
  data?: any[]
}

type PermissionData = {
  name: string
  grouped?: string
  function?: string
  id: number
}

type TypesFormProps = {
  initialValues?: PermissionAssociatedData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormPermissionsAssociated = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<PermissionAssociatedData>()
  const [permissions, setPermissions] = useState<PermissionData[]>([])
  const [hasFocus, setHasFocus] = useState([{ id: 0, hasFocus: false }])
  const [hasFocusPermissionAssociated, setHasFocusPermissionAssociated] =
    useState([{ id: 0, hasFocus: false }])
  const [permissionRoute, setPermissionRoute] = useState<any[]>([
    { id: 0, name: '' }
  ])
  const [selected, setSelected] = useState([{ id: 0, selected: false }])
  const [selectedPermissionRoute, setSelectedPermissionRoute] = useState([
    { id: 0, selected: false }
  ])
  const [permissionsGrouped, setPermissionsGrouped] = useState<
    PermissionData[]
  >([])
  const [permissionsFunction, setPermissionsFunction] = useState<
    PermissionData[]
  >([])
  const [permissionsRoutes, setPermissionsRoutes] = useState<PermissionData[]>(
    []
  )
  const [selectedPermissionsAssociated, setSelectedPermissionsAssociated] =
    useState<PermissionData[]>([])
  const { activeLoading, disableLoading } = useLoading()

  const loadPermissions = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/users/permissions')
      setPermissionsGrouped(filterDuplicatedsObject(data, 'grouped'))
      setPermissions(data)
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar as permissões',
        description:
          'Ocorreu um erro ao carregar as permissões, por favor, tente novamente.'
      })
      disableLoading()
      updateDataTable()
    }
  }, [activeLoading, addToast, disableLoading, updateDataTable])

  useEffect(() => {
    loadPermissions()
  }, [loadPermissions])

  useEffect(() => {
    activeLoading()
    if (initialValues?.routesAssociated) {
      setDefaultValues({
        id: initialValues?.id,
        permission_id: initialValues?.permission_id,
        grouped: initialValues?.permission?.grouped,
        function: initialValues?.permission?.function
      })
      setSelectedPermissionsAssociated(initialValues?.routesAssociated)
      setHasFocusPermissionAssociated(
        initialValues?.routesAssociated.map(
          item =>
            item && {
              ...item,
              hasFocus: false
            }
        )
      )
      setSelected(
        initialValues?.routesAssociated.map(
          item =>
            item && {
              ...item,
              selected: false
            }
        )
      )
      setPermissionRoute([{ ...initialValues.permission }])
      if (permissions.length) {
        const filterPermissionsFunction = permissions.filter(
          (permission: PermissionData) => {
            return (
              permission?.grouped?.toLowerCase() ===
              initialValues?.permission?.grouped?.toLowerCase()
            )
          }
        )
        const filterPermissionsRoutes = permissions.filter(
          (permission: PermissionData) => {
            return (
              permission?.function?.toLowerCase() ===
              initialValues?.permission?.function?.toLowerCase()
            )
          }
        )
        setPermissionsFunction(
          filterDuplicatedsObject(filterPermissionsFunction, 'function')
        )
        setPermissionsRoutes(filterPermissionsRoutes)
      }
    }
    if (typeForm === 'create') {
      setSelectedPermissionsAssociated([{ id: 0, name: '' }])
    }
    disableLoading()
  }, [activeLoading, disableLoading, initialValues, permissions, typeForm])

  const onSubmit = async (data: PermissionAssociatedData) => {
    data.permission_id = permissionRoute.map(item => Number(item.id))[0]
    data.routes = selectedPermissionsAssociated.map(item => item.id)
    delete data.data
    delete data.grouped
    delete data.function
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const dataUpdate = {
          ...data
        }
        const id = initialValues?.idUpdate

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)
          updateDataTable()
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  const handleSelectFilter = useCallback(
    (event: ChangeEvent<HTMLSelectElement>, fieldFilter: string) => {
      const filterPermissions = permissions.filter((permission: any) => {
        return (
          permission[fieldFilter]?.toLowerCase() ===
          event.target.value.toLowerCase()
        )
      })
      return filterPermissions
    },
    [permissions]
  )

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <FormContent className="">
        <div className="row">
          <div className="col-md-3">
            <Select
              label="Grupo"
              name="grouped"
              className="form-control"
              defaultValue=""
              blank
              onChange={event => {
                setPermissionsFunction(
                  filterDuplicatedsObject(
                    handleSelectFilter(event, 'grouped'),
                    'function'
                  )
                )
              }}
              options={permissionsGrouped.map(
                permissionGrouped =>
                  permissionGrouped && {
                    name: permissionGrouped.grouped,
                    value: permissionGrouped.grouped
                  }
              )}
              rules={{ required: true }}
            />
          </div>
          <div className="col-md-3">
            <Select
              label="Função"
              name="function"
              className="form-control"
              defaultValue=""
              blank
              onChange={event => {
                setPermissionsRoutes(handleSelectFilter(event, 'function'))
              }}
              disabled={
                typeForm === 'create' ? !permissionsFunction.length : false
              }
              options={permissionsFunction.map(
                permissionFunction =>
                  permissionFunction && {
                    name: permissionFunction.function,
                    value: permissionFunction.function
                  }
              )}
              rules={{ required: true }}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="permission_id" className="control-label">
              Rotas Principal
            </label>
            {permissionRoute?.map((item, index) => {
              return (
                <div key={Math.random() * 999}>
                  {SearchComponentProduct({
                    index,
                    data: permissionRoute,
                    setData: setPermissionRoute,
                    searchData: permissionsRoutes,
                    selected: selectedPermissionRoute,
                    setSelected: setSelectedPermissionRoute,
                    disabled: !permissionsRoutes.length,
                    label: 'Rotas Principal',
                    hasFocus,
                    setHasFocus
                  })}
                </div>
              )
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label htmlFor="routes" className="control-label">
              Rotas Auxiliares
            </label>
            <div>
              {selectedPermissionsAssociated?.map(
                (selectedPermissionAssociated, index) => {
                  return (
                    <div
                      key={
                        Math.random() * selectedPermissionsAssociated.length + 1
                      }
                      style={{ display: 'flex', alignItems: 'baseline' }}
                    >
                      {SearchComponentProduct({
                        index,
                        data: selectedPermissionsAssociated,
                        setData: setSelectedPermissionsAssociated,
                        searchData: permissions,
                        selected,
                        setSelected,
                        disabled: !permissionRoute[0].id,
                        label: 'Rotas Auxiliares',
                        hasFocus: hasFocusPermissionAssociated,
                        setHasFocus: setHasFocusPermissionAssociated
                      })}
                      <div style={{ marginLeft: 5, padding: 5 }}>
                        <IconRemove
                          onClick={() => {
                            setSelectedPermissionsAssociated(
                              selectedPermissionsAssociated.filter(
                                item =>
                                  item.id !== selectedPermissionAssociated.id
                              )
                            )
                          }}
                        />
                      </div>
                    </div>
                  )
                }
              )}
              <a
                onClick={() => {
                  setSelectedPermissionsAssociated([
                    ...selectedPermissionsAssociated,
                    { id: 0, name: '' }
                  ])
                  setHasFocusPermissionAssociated([
                    ...hasFocusPermissionAssociated,
                    { id: 0, hasFocus: false }
                  ])
                  setSelected([...selected, { id: 0, selected: false }])
                }}
                className="btn dark btn-sm sbold uppercase"
              >
                <span
                  className="fa fa-plus"
                  aria-hidden="true"
                  style={{ marginRight: 5 }}
                />
                Adicionar
              </a>
            </div>
          </div>
        </div>
      </FormContent>
      <div className="form-actions right" style={{ marginTop: 20 }}>
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
