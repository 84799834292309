export const headers = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Cardápio', field: 'menu_food.name', sortable: true },
  {
    name: 'Ativo',
    field: 'is_active',
    sortable: false
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersPlanFrequencyQueryApi = [
  { name: 'Frequência', field: 'frequency', sortable: true },
  {
    name: 'Periodicidade',
    field: 'payament_form.periodicity',
    sortable: false
  },
  { name: 'Valor', field: 'payament_form.value', sortable: false },
  {
    name: 'Ativo',
    field: 'is_active',
    sortable: false
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
