import StudentsList from '../../pages/Commercial/Clients/Students/List'
import StudentsView from '../../pages/Commercial/Clients/Students/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const StudentsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/students" exact component={StudentsList} />
    <Route
      path="/commercial/students/view/:id"
      component={StudentsView}
      exact
    />
  </CustomSwitch>
)
