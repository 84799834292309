import styled from 'styled-components'

interface ITooltipProps {
  width?: number
  widthContainer?: string
}

export const Container = styled.div<ITooltipProps>`
  position: relative;
  width: ${({ width }) => width || '20px'};

  svg {
    position: absolute;
    font-size: 1.4rem;
  }
  span {
    width: ${({ width }) => width}px;
    background: #f1faff;
    padding: 5px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    /* color: #312e38; */
    color: #3f4254;
    text-align: center;

    &::before {
      content: '';
      border-style: solid;
      border-color: #f1faff transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`
