export const headers = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Ativo', field: 'is_active', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersMenuFood = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Período', field: 'period', sortable: true },
  { name: 'Ativo', field: 'is_active', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
