import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import Form, { Input, Select } from 'components/Form'
import { DateInput } from 'components/Form/date'
import { FilterContainer } from './styles'
import api from 'services/api'
import { apiListInstitution } from '../domain/api'
import { useToast } from 'hooks/toast'
import {
  FORMS_PAYAMENT,
  PAYAMENT_FORM_PERIODICITIES,
  SITUATION_TYPES_CONTRACT
} from 'commons/constants/commercial'
import Container from 'components/Container'
import DataTable from 'components/DataTable'

const ContractList = (): JSX.Element => {
  const { addToast } = useToast()
  const [startDate, setStartDate] = useState<Date>()
  const [finalDate, setFinalDate] = useState<Date>()
  const [situation, setSituation] = useState<string>()
  const [formPayament, setFormPayament] = useState<string>()
  const [institutionOptions, setInstitutionOptions] = useState<
    IInstitutionData[]
  >([])
  const [institution, setInstitution] = useState<string>()
  console.log(institution)
  // PAYAMENT_FORM_PERIODICITIES
  const [parameters, setParameters] = useState<any>()
  const history = useHistory()

  useEffect(() => {
    api
      .get(apiListInstitution())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IInstitutionData, b: IInstitutionData) =>
            a.company_social_name.localeCompare(b.company_social_name)
        )
        setInstitutionOptions(orderedData)
      })
      .catch(e => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [])

  const onSearch = async () => {
    const query = []
    if (formPayament) {
      query.push(`form_payament=${formPayament}`)
    }
    if (startDate) {
      query.push(
        `start_validity=${moment(startDate).format().substring(0, 10)}`
      )
    }
    if (finalDate) {
      query.push(`end_validity=${moment(finalDate).format().substring(0, 10)}`)
    }
    if (situation) {
      query.push(`situation=${situation}`)
    }

    if (institution) {
      query.push(`institution_id=${institution}`)
    }

    history.replace(`/commercial/contracts?${query.join('&')}`)
    if (startDate || finalDate || situation || formPayament || institution) {
      setParameters([
        {
          form_payament: formPayament || undefined,
          start_validity: startDate ? moment(startDate).toDate() : undefined,
          end_validity: finalDate ? moment(finalDate).toDate() : undefined,
          institution_id: institution || undefined,
          situation: situation || undefined
        }
      ])
    } else {
      setParameters([])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const formPayament = location.get('form_payament')
    const startDate = location.get('start_validity')
    const finalDate = location.get('end_validity')
    const situation = location.get('situation')

    if (startDate || finalDate || situation || formPayament) {
      if (formPayament) {
        setFormPayament(formPayament)
      }
      if (startDate) {
        setStartDate(moment(`${startDate} 00:00:00`).toDate())
      }
      if (finalDate) {
        setFinalDate(moment(`${finalDate} 00:00:00`).toDate())
      }
      if (situation) {
        setSituation(situation)
      }
      if (institution) {
        setInstitution(institution)
      }
      setParameters([
        {
          form_payament: formPayament || undefined,
          start_validity: startDate ? moment(startDate).toDate() : undefined,
          end_validity: finalDate ? moment(finalDate).toDate() : undefined,
          institution_id: institution || undefined,
          situation: situation || undefined
        }
      ])
      console.log(parameters)
    } else {
      setParameters([])
    }
  }, [])
  console.log(location)
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContainer>
        <Form onSubmit={onSearch} defaultValues={{}} className="col-md-12">
          <div className="col-md-12 d-flex">
            <div className="col-md-3 pe-8">
              <Select
                label="Instituição"
                name="institution_id"
                options={institutionOptions?.map(institution => {
                  return {
                    name: institution.company_social_name,
                    value: institution.id
                  }
                })}
                onChange={e => setInstitution(e.target.value)}
                value={institution}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-3 pe-8">
              <Select
                label="Forma de pagamento"
                name="form_payament"
                options={FORMS_PAYAMENT?.map(form_payament => {
                  return {
                    name: form_payament,
                    value: form_payament
                  }
                })}
                onChange={e => setFormPayament(e.target.value)}
                value={formPayament}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-2 pe-8">
              <DateInput
                label="Data inicial"
                name="start_validity"
                onChange={date => setStartDate(date)}
                selected={startDate}
                controlled
              />
            </div>
            <div className="col-md-2 pe-8">
              <DateInput
                label="Data final"
                name="end_validity"
                onChange={date => setFinalDate(date)}
                selected={finalDate}
                controlled
              />
            </div>
            <div className="col-md-2">
              <Select
                label="Situação"
                name="situation"
                options={SITUATION_TYPES_CONTRACT?.map(situation => {
                  return {
                    name: situation,
                    value: situation
                  }
                })}
                onChange={e => setSituation(e.target.value)}
                value={situation}
                controlled
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <footer className="col-md-12 d-flex justify-content-end pb-5 gap-5">
            <button
              type="reset"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setStartDate(undefined)
                setFinalDate(undefined)
                setSituation(undefined)
                setInstitution(undefined)
                setFormPayament(undefined)
              }}
            >
              LIMPAR
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              BUSCAR
            </button>
          </footer>
        </Form>
        <hr />
      </FilterContainer>

      <div>
        <h3 className="h3 pt-6">Listagem</h3>
      </div>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'id' }}
        headers={headers}
        searchParameters={parameters}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: contract => `/commercial/contracts/view/${contract.id}`,
            style: { margin: '0 5px' }
          }
        ]}
      />
    </Container>
  )
}
export default ContractList
