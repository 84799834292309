export const headers = [
  { name: 'Tipo', field: 'menu_food_type.name', sortable: true },
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Período', field: 'period', sortable: true },
  { name: 'Ativo', field: 'is_active', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headerMenuDishes = [
  { name: 'Tipo', field: 'dish.dish_type.name', sortable: true },
  { name: 'Item', field: 'dish.name', sortable: true },
  { name: 'Quantidade', field: 'dish_amount', sortable: true },
  { name: 'Descrição', field: 'dish.description', sortable: false },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headerMenuInstitutions = [
  {
    name: 'Nome Fantasia',
    field: 'institution.company_social_name',
    sortable: true
  },
  { name: 'CNPJ', field: 'institution.cnpj', sortable: false },
  { name: 'Telefone', field: 'institution.phone', sortable: false },
  {
    name: 'Ativo',
    field: 'institution.is_active',
    sortable: false
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
