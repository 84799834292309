import { PageLink } from 'assets/layout/core'

export const breadcrumbUpdate: PageLink[] = [
  {
    title: 'Itens',
    path: '/commercial/dishes',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
]
