import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'

const InstitutionList = (): JSX.Element => (
  <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={toolsList}>
    <DataTable
      source={nameSource}
      entity={nameEntity}
      format={{ orderBy: 'company_social_name' }}
      headers={headers}
      actions={[
        {
          name: 'Visualizar',
          title: 'Visualizar',
          spanIcon: 'fa fa-search',
          linkTo: institutition =>
            `/commercial/institutions/view/${institutition.id}`,
          style: { margin: '0 5px' }
        },
        {
          name: 'Editar',
          title: 'Editar',
          spanIcon: 'fa fa-edit',
          linkTo: institution =>
            `/commercial/institutions/update/${institution.id}`
        }
      ]}
    />
  </Container>
)

export default InstitutionList
