interface IInfoLabel {
  title: string
  description: string
  colunm?: number
}
const InfoLabel = ({ title, description, colunm }: IInfoLabel) => {
  return (
    <div className={`col-md-${colunm}`}>
      <label className="col-form-label fw-bold form-label fw-bold fs-6">
        {title}
      </label>
      <p className="fw-bolder fs-6 text-gray-800">{description}</p>
    </div>
  )
}

export default InfoLabel
