import Container from '../../../../components/Container'
import { nameActions } from '../domain/info'
import { breadcrumbCreate } from '../domain/breadcrumb'
import { toolsCreate } from '../domain/tools/create'
import FormRegister from '../components/FormRegister'

const InstitutionCreate = (): JSX.Element => (
  <Container
    pageTitle={nameActions.create.name}
    breadcrumb={breadcrumbCreate}
    tools={toolsCreate}
  >
    <FormRegister />
  </Container>
)

export default InstitutionCreate
