import Container from '../../../../components/Container'
import { nameActions } from '../domain/info'
import { breadcrumbCreate } from '../domain/breadcrumb'
import { toolsCreate } from '../domain/tools/create'
import { FormPlan } from '../components/Form'

const PlanCreate = (): JSX.Element => (
  <Container
    pageTitle={nameActions.create.name}
    breadcrumb={breadcrumbCreate}
    tools={toolsCreate}
  >
    <FormPlan typeForm="create" />
  </Container>
)

export default PlanCreate
