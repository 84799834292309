import styled from 'styled-components'

export const FormContent = styled.div`
  padding: 1em;

  display: 'block';
  flex-direction: column;

  > div {
    display: 'grid';
    grid-template-columns: 1fr 1fr 1fr;
  }
`
