import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { FormMenu } from '../components/Form'
import Tabs from '../../../components/Tabs'
import Tab from '../../../components/Tabs/Tab'
import DataTable from '../../../components/DataTable'
import api from '../../../services/api'
import { useToast } from '../../../hooks/toast'
import Modal from '../../../components/Modal'
import { useLoading } from '../../../hooks/loading'
import { Alert } from '../../../components/Alert'
import { useUpdateDataTable } from '../../../hooks/dataTable'
import { nameActions, nameEntity } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { MenuTable, MenuTableRow, LinkContainer } from './styles'
import Container from '../../../components/Container'

interface MenuData {
  id: number
  parent_id: number | null
  name: string
  controller: string
  method: string
  action: string
  created_at: string
  updated_at: string
}

type TModalType = 'closed' | 'create' | 'update'

const MenuView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [menu, setMenu] = useState<MenuData>({
    action: '',
    controller: '',
    created_at: '',
    id: 1,
    method: '',
    name: '',
    parent_id: 1,
    updated_at: ''
  })
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const [currentItemUpdate, setCurrentItemUpdate] = useState<MenuData>(
    {} as MenuData
  )

  const [modalTypeOpen, setModalTypeOpen] = useState<TModalType>('closed')

  const [idParentCreate, setIdParentCreate] = useState<number>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [menuList, setMenuList] = useState<MenuData[]>()
  const [currentMenuList, setCurrentMenuList] = useState<MenuData[]>()

  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])

  useEffect(() => {
    const menu = menuList?.find(menu => menu.id?.toString() === id)
    if (menu) {
      setMenu(menu)
    }
    const menuListItem = menuList?.filter(
      menu => menu.parent_id?.toString() === id
    )
    if (menuListItem) {
      setCurrentMenuList(menuListItem)
    }
  }, [id, menuList])

  const handleClickOnClose = () => {
    setModalTypeOpen('closed')

    updateDataTable()
  }
  const handlerOnClickButtonEditInCurrentRow = (currentValue: MenuData) => {
    setCurrentItemUpdate(currentValue)
    setModalTypeOpen('update')
  }

  const handleClickOnOpenModalCreate = (id: number) => {
    setIdParentCreate(id)
    setModalTypeOpen('create')
  }

  const refModal = useRef(null)
  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadMenu(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<MenuData[]>(apiList())
        const { data } = response
        setMenuList(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o menu',
          description:
            'Houve um error ao carregar o menu, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadMenu()
  }, [
    activeLoading,
    addToast,
    disableLoading,
    history,
    id,
    location.pathname,
    updateDataTable
  ])

  const handlerOnClickButtonRemoveInCurrentRow = ({ id, name }: MenuData) => {
    setIsActiveAlert({ id, name, isActive: true })
  }

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usado.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usado.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="id"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cód.
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{menu?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Nome
                </label>
                <p className="fw-bolder fs-6 text-gray-800">{menu?.name}</p>
              </div>
            </div>
            {menu?.controller && (
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="name"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Controlador
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {menu?.controller}
                  </p>
                </div>
              </div>
            )}
            {menu?.method && (
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="name"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Método
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{menu?.method}</p>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            {menu?.action && (
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="name"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Ação
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{menu?.action}</p>
                </div>
              </div>
            )}
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="created"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cadastrado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {menu?.created_at}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="updated"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Atualizado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {menu?.updated_at}
                </p>
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="row">
            <div className="">
              <Tabs>
                <Tab title="Submenus" key={0}>
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                      <div className="">
                        <a
                          href="#"
                          onClick={() =>
                            handleClickOnOpenModalCreate(Number(id))
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="fa fa-plus" /> Adicionar
                        </a>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <MenuTable
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Nome</th>
                            <th>Controlador</th>
                            <th>Método</th>
                            <th>Ação</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentMenuList
                            ?.filter(item => item.parent_id?.toString() === id)
                            .map(currentMenu => (
                              <React.Fragment key={currentMenu.id}>
                                <MenuTableRow>
                                  <td>{currentMenu.name}</td>
                                  <td>{currentMenu.controller}</td>
                                  <td>{currentMenu.method}</td>
                                  <td>{currentMenu.action}</td>
                                  <td>
                                    <LinkContainer>
                                      <a
                                        href="#"
                                        onClick={() =>
                                          handlerOnClickButtonEditInCurrentRow(
                                            currentMenu
                                          )
                                        }
                                      >
                                        <span className="fa fa-edit" />
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() =>
                                          handlerOnClickButtonRemoveInCurrentRow(
                                            currentMenu
                                          )
                                        }
                                      >
                                        <span className="fa fa-remove" />
                                      </a>
                                      {!currentMenu.controller && (
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleClickOnOpenModalCreate(
                                              Number(currentMenu.id)
                                            )
                                          }
                                        >
                                          <span className="fa fa-plus" />
                                        </a>
                                      )}
                                    </LinkContainer>
                                  </td>
                                </MenuTableRow>
                                {menuList
                                  ?.filter(i => i.parent_id === currentMenu.id)
                                  .map(subMenu => (
                                    <tr key={subMenu.id}>
                                      <td>{subMenu.name}</td>
                                      <td>{subMenu.controller}</td>
                                      <td>{subMenu.method}</td>
                                      <td>{subMenu.action}</td>
                                      <td>
                                        <LinkContainer>
                                          <div
                                            onClick={() =>
                                              handlerOnClickButtonEditInCurrentRow(
                                                subMenu
                                              )
                                            }
                                          >
                                            <i className="fa fa-edit" />
                                          </div>
                                          <div
                                            onClick={() =>
                                              handlerOnClickButtonRemoveInCurrentRow(
                                                subMenu
                                              )
                                            }
                                          >
                                            <i className="fa fa-remove" />
                                          </div>
                                        </LinkContainer>
                                      </td>
                                    </tr>
                                  ))}
                              </React.Fragment>
                            ))}
                        </tbody>
                      </MenuTable>
                    </div>
                  </div>
                </Tab>
                <Tab title="Histórico" key={1}>
                  <div className="">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalTypeOpen !== 'closed'}
        pageTitle={modalTypeOpen === 'create' ? 'Adicionar' : 'Editar'}
        Children={
          <FormMenu
            typeForm={modalTypeOpen === 'create' ? 'create' : 'update'}
            initialValues={
              modalTypeOpen === 'update' && {
                idUpdate: currentItemUpdate.id,
                name: currentItemUpdate.name,
                controller: currentItemUpdate.controller,
                method: currentItemUpdate.method,
                action: currentItemUpdate.action
              }
            }
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(idParentCreate)
            }}
          />
        }
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${menu?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(menu?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default MenuView
